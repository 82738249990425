import React from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import { alpha, styled } from '@mui/material'

import slugPrefix, { slugPrefixProps } from 'helpers/slug-prefix'

interface StyleProps {
  colorVariant?: 'primary' | 'primary-light' | 'primary-dark'
  underline?: boolean
}

export interface InternalLinkProps
  extends Omit<GatsbyLinkProps<{}>, 'ref' | 'to'>,
    slugPrefixProps,
    StyleProps {}

const StyledLink = styled(GatsbyLink, {
  shouldForwardProp: (prop) => prop !== 'colorVariant' && prop !== 'underline',
})<StyleProps>(({ colorVariant, underline, theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  transition: theme.transitions.create(['color', 'border-bottom-color']),
  ['&:hover']: {
    color: theme.palette.primary.main,
  },

  ...(underline && {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.primary.main,
    display: 'inlineBlock',
    paddingBottom: '0.3rem',
  }),

  ...(colorVariant === 'primary' && {
    color: theme.palette.primary.main,
    ['&:hover']: {
      color: theme.palette.text.primary,
    },
  }),
  ...(colorVariant === 'primary-light' && {
    color: theme.palette.primary.main,
    ['&:hover']: {
      color: 'white',
    },
    ...(underline && {
      borderBottomColor: alpha(theme.palette.primary.main, 0.4),
      ['&:hover']: {
        color: 'white',
        borderBottomColor: alpha(theme.palette.common.white, 0.4),
      },
    }),
  }),
  ...(colorVariant === 'primary-dark' && {
    color: theme.palette.primary.main,
    ['&:hover']: {
      color: theme.palette.text.primary,
    },
    ...(underline && {
      borderBottomColor: alpha(theme.palette.primary.main, 0.4),
      ['&:hover']: {
        color: theme.palette.text.primary,
        borderBottomColor: alpha(theme.palette.text.primary, 0.4),
      },
    }),
  }),
}))

const InternalLink = ({ slug, apiKey, ...props }: InternalLinkProps) => {
  return (
    <StyledLink to={slugPrefix({ slug: slug, apiKey: apiKey })} {...props} />
  )
}

export default InternalLink
