import React, { memo } from 'react'
import { Box, BoxProps } from '@mui/material'
import { Star } from 'phosphor-react'
import InternalLink from '@atoms/links/InternalLink'

import Flex from '@atoms/layout/Flex'

export interface Props extends BoxProps {
  date: string
  category: Queries.DatoCmsBlogCategory
  categoryLinkColorVariant: 'primary-light' | 'primary-dark'
  isSticky?: boolean
}

const DateAndCategory = ({
  date,
  category,
  categoryLinkColorVariant,
  isSticky,
  ...props
}: Props) => {
  return (
    <Flex typography="caption" color="primary.main" {...props} alignCenter>
      <Box mr="auto">
        {/* {date} -{' '} */}
        <InternalLink
          slug={category.slug}
          apiKey={category.model.apiKey}
          colorVariant={categoryLinkColorVariant}
          underline
        >
          {category.name}
        </InternalLink>
      </Box>
      {/* {isSticky && (
        <Box ml={1}>
          <Star fontSize="1.5rem" />
        </Box>
      )} */}
    </Flex>
  )
}

export default memo(DateAndCategory)
