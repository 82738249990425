import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, BoxProps, lighten, styled } from '@mui/material'

const Hover = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}))

const ColorImage = styled(GatsbyImage)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  transition: theme.transitions.create(['opacity'], {
    duration: 500,
  }),
  opacity: 1,
  ['&:hover']: {
    opacity: 0,
  },
}))

const TintImageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.secondary.main, 0.05),
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
}))

const TintImage = styled(GatsbyImage)(({ theme }) => ({
  cursor: 'pointer',
  opacity: 1,
  mixBlendMode: 'overlay',
  position: 'relative',
  display: 'block',
  width: '100%',
  height: '100%',
}))

interface Props extends BoxProps {
  tintImage: IGatsbyImageData
  colorImage: IGatsbyImageData
}

const ImageHover = ({ tintImage, colorImage, ...props }: Props) => (
  <Hover {...props}>
    <TintImageWrapper>
      <TintImage image={tintImage} alt="" />
    </TintImageWrapper>
    <ColorImage image={colorImage} alt="" />
  </Hover>
)

export default memo(ImageHover)
