import React, { memo } from 'react'
import { alpha, styled } from '@mui/material'

import InternalButtonLink, {
  InternalButtonLinkProps,
} from '../buttonLinks/InternalButtonLink'

export type ButtonStyleColorVariant = 'primary-text' | 'white-text'

interface ButtonStyleProps {
  colorVariant?: ButtonStyleColorVariant
}

export interface ButtonProps extends InternalButtonLinkProps, ButtonStyleProps {
  children: React.ReactNode
}

const StyledButton = styled(InternalButtonLink)(({ theme }) => ({
  backgroundColor: alpha('#ad9882', 0.14),
  color: theme.palette.primary.main,
  margin: '5px',
  ['&:hover']: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}))

const TagButton = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledButton variant="contained" sizeVariant="xs" {...props}>
      {children}
    </StyledButton>
  )
}

export default memo(TagButton)
