import React, { memo } from 'react'
import {
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image'
import {
  Box,
  BoxProps,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import InternalLink from '@atoms/links/InternalLink'
import DateAndCategory from '@molecules/blog/DateAndCategory'
import TagButton from '@atoms/buttons/TagButton'
import ImageHover from '@atoms/media/ImageHover'

type card = (
  | Queries.DatoCmsBlogPost
  | Queries.DatoCmsProject
  | Queries.DatoCmsKeyword
) & {
  heroImageMainSmall?: IGatsbyImageData
  heroImageHoverSmall?: IGatsbyImageData
  heroImageMainLarge?: IGatsbyImageData
  heroImageHoverLarge?: IGatsbyImageData
}

interface StyleProps {
  highlight?: true
  minimalCard?: boolean
}

export interface Props extends BoxProps, StyleProps {
  data: card
}

const StyledCard = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.masonrySpacing,
  ...(highlight && {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      ...(highlight && {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(4),
      }),
    },
  }),
}))
const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      flex: 1,
      width: '50%',
      overflow: 'hidden',
      position: 'relative',
      alignSelf: 'stretch',
    }),
  },
}))

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      flex: 1,
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    }),
  },
}))
const ActionsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      width: '60%',
      display: 'flex',
      justifyContent: 'center',
    }),
  },
}))
const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'highlight' && prop !== 'minimalCard',
})<StyleProps>(({ highlight, minimalCard, theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      textAlign: 'center',
      alignItems: 'center',
      padding: 0,
      width: '60%',
    }),
  },
  ...(minimalCard && {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  }),
}))

const StyledCardActions = styled(CardActions, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  display: 'block',
  paddingTop: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      padding: 0,
    }),
  },
}))

const CardHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<StyleProps>(({ highlight, theme }) => ({
  [theme.breakpoints.up('lg')]: {
    ...(highlight && {
      fontSize: '3.6rem',
    }),
  },
}))

type ImageStyleProps = {
  useArtDirectionImages?: boolean
}

const StyledImageHover = styled(ImageHover, {
  shouldForwardProp: (prop) => prop !== 'useArtDirectionImages',
})<ImageStyleProps>(({ useArtDirectionImages, theme }) => ({
  ...(useArtDirectionImages && {
    ['.gatsby-image-wrapper']: {
      [theme.breakpoints.between('sm', 'lg')]: {
        width: '100%',
        display: 'block',
        height: '0',
        paddingTop: '50%',
      },
    },
  }),
  ['.gatsby-image-wrapper']: {
    [theme.breakpoints.up('lg')]: {
      height: '100%',
    },
  },
}))

const StyledLink = styled(InternalLink)(({ theme }) => ({
  overflow: 'hidden',
  display: 'block',
  height: '100%',
}))

const Card = ({ data, highlight, minimalCard }: Props) => {
  const theme = useTheme()

  let dataType: string | undefined = undefined
  if (data.model && data.model.apiKey) {
    dataType = data.model.apiKey
  }

  const useArtDirectionImages =
    highlight &&
    data.heroImageMainSmall &&
    data.heroImageHoverSmall &&
    data.heroImageMainLarge &&
    data.heroImageHoverLarge
      ? true
      : false

  let images = undefined
  let hoverImages = undefined
  if (useArtDirectionImages) {
    images = withArtDirection(
      // @ts-ignore
      getImage(data.heroImageMainLarge),
      [
        {
          media: `(min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${theme.breakpoints.values.lg}px)`,
          image: getImage(data.heroImageMainSmall),
        },
      ]
    )
    hoverImages = withArtDirection(
      // @ts-ignore
      getImage(data.heroImageHoverLarge),
      [
        {
          media: `(min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${theme.breakpoints.values.lg}px)`,
          image: getImage(data.heroImageHoverSmall),
        },
      ]
    )
  }

  return (
    <StyledCard elevation={0} highlight={highlight}>
      <StyledCardMedia highlight={highlight}>
        <StyledLink slug={data.slug} apiKey={data.model.apiKey}>
          <StyledImageHover
            useArtDirectionImages={useArtDirectionImages}
            colorImage={
              useArtDirectionImages && images
                ? images
                : data.heroImageMain.gatsbyImageData
            }
            tintImage={
              useArtDirectionImages && hoverImages
                ? hoverImages
                : data.heroImageHover.gatsbyImageData
            }
          />
        </StyledLink>
      </StyledCardMedia>
      <ContentWrapper highlight={highlight}>
        <StyledCardContent highlight={highlight} minimalCard={minimalCard}>
          {dataType === 'blog_post' && (
            <DateAndCategory
              date={data.meta.createdAt}
              category={data.category[0]}
              categoryLinkColorVariant="primary-dark"
              isSticky={data.isSeoPost}
              mb={1}
            />
          )}

          <InternalLink slug={data.slug} apiKey={data.model.apiKey}>
            <CardHeading
              component="h3"
              variant="cardHeading"
              highlight={highlight}
            >
              {data.title}
            </CardHeading>
          </InternalLink>
          {data.decription && (
            <Box color="textLight" mt={2} mb={4}>
              {data.description}
            </Box>
          )}
        </StyledCardContent>
        {data.keywordTags && data.keywordTags.length > 0 && (
          <ActionsWrapper highlight={highlight}>
            <StyledCardActions highlight={highlight}>
              {data.keywordTags.map(
                (tag: Queries.keywordButtonFragment, index: number) => {
                  if (tag.shortTitle) {
                    return (
                      <TagButton
                        key={`${tag.originalId}-${index}`}
                        slug={tag.slug}
                        apiKey={tag.model.apiKey}
                      >
                        {tag.shortTitle}
                      </TagButton>
                    )
                  }
                }
              )}
            </StyledCardActions>
          </ActionsWrapper>
        )}
      </ContentWrapper>
    </StyledCard>
  )
}

export default memo(Card)
